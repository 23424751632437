<template>
  <div class="sign-up" >
    <div class="sign-left">
      <signInUpLeft />
    </div>
    <div class="sign-up-wrap" >
      <div class="sign-up-content">
        <a-spin :spinning="spinning">
        <h3>Join ArtemisAds</h3>
        <p class="to-sign-in">Already have an account? <a class="link" href="#/sign-in">Log in to ArtemisAds</a></p>
        <a-form>
          <a-form-item 
            v-bind="validateInfos.username"
          >
            <div class="input">
              <span>Full Name</span>
              <artmisads-input v-model:value="modelRef.username"/>
            </div>
          </a-form-item>
          <a-form-item
            v-bind="validateInfos.email"
            name="email"
          >
            <div class="input input-second">
              <span>Email Address</span>
              <artmisads-input @blur="() => validate('email')" v-model:value="modelRef.email"/>
            </div>
          </a-form-item>
          <div class="password">
            <div class="password-wrap">
              <a-form-item
                v-bind="validateInfos.password"
              >
                <div class="password-input">
                  <span class="password-title">Password</span>
                  <artmisads-input-password v-model:visible="passwordVisible" @blur="() => validate('password')"  v-model:value="modelRef.password"/>
                </div>
              </a-form-item>
            </div>
            <div class="password-wrap">
              <a-form-item
                v-bind="validateInfos.confirmPassword"
              >
                <div class="password-input">
                  <span class="password-title">Comfirm Password</span>
                  <artmisads-input-password v-model:visible="passwordVisible" @blur="() => validate('confirmPassword')" v-model:value="modelRef.confirmPassword"/>
                </div>
              </a-form-item>
            </div>
          </div>
          <div class="sign-btn">
            <a-form-item>
              <artmisads-button @click="onSubmit" type="primary" html-type="submit">Sign Up With Email</artmisads-button>
            </a-form-item>
          </div>
        </a-form>
        <div class="terms-conditions">
          By joining or signing in, you agree to our <a class="link" target="_blank" href="/#/terms-of-service">Terms of Service</a> and <a class="link" href="/#/privacy-policy" target="_blank">Privacy Policy</a>
        </div>
        <successModal v-if="showSuccessModal" :setShowSuccessModal="setShowSuccessModal" :resendEmail="resendEmail"/>
        </a-spin>
      </div>
    </div>
    <contextHolder />
  </div>
</template>
<script setup>
  import { reactive, ref } from 'vue';
  import signInUpLeft from '../../components/signInUpLeft.vue';
  import successModal from './successModal.vue';
  import { Form, message, notification } from 'ant-design-vue';

  import { signApi } from '../../server/index'

  const showSuccessModal = ref(false);
  const [api, contextHolder] = notification.useNotification();
  const openNotification = description => {
    api.info({
      message: `Warning`,
      description: description,
      placement: 'topRight',
      duration: 10
    });
  };
  const passwordVisible = ref(false);
  const spinning = ref(false);
  const useForm = Form.useForm;
  const props = defineProps(['goTo']);

  const modelRef = reactive({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  const rulesRef = reactive({
    username: [{
      required: true,
      message: 'Full Name cannot be empty',
    }],
    email: [{
      required: true,
      type: 'email',
      message: 'Invalid email',
      trigger: ['blur']
    }],
    password: [{
      required: true,
      validator: validatePassword,
      trigger: ['blur']
    }],
    confirmPassword: [{
      required: true,
      validator: validateConfirmPassword,
      trigger: ['blur']
    }],
  });

  const { validate, validateInfos, resetFields } = useForm(modelRef, rulesRef);

  function resendEmail () {
    resetFields();
    showSuccessModal.value = false;
  }

  function setShowSuccessModal (status) {
    showSuccessModal.value = status;
  }

  function validatePassword  (_rule, value) {
    if (value === '') {
      return Promise.reject('Password cannot be empty');
    } else if (value.length < 8 || !value.match(/[0-9]/) || !value.match(/[A-Z]/)) {
      return Promise.reject("Password must be at least 8 characters with 1 upper case letter and 1 number");
    } else {
      return Promise.resolve();
    }
  };

  function validateConfirmPassword (_rule, value) {
    if (value === '') {
      return Promise.reject('Please input the password again');
    } else if (value !== modelRef.password) {
      return Promise.reject("Passwords do not match");
    } else {
      return Promise.resolve();
    }
  };

  async function onSubmit () {
    const validateRes = await validate();
    if (!validateRes.errorFields) {
      spinning.value = true;
      const params = validateRes;
      const res = await signApi.signUp(params);
      const { error, success } = res;
      if (success) {
        showSuccessModal.value = true;
      } else {
        openNotification(error);
      }
      spinning.value = false;
    }
  }

</script>
<style lang="less" scoped>
  .sign-up {
    width: 100vw;
    height: 100dvh;
    min-height: 700px;
    overflow: hidden;
    display: flex;
    .warning {
      position: fixed;
      top: 8px;
      right: 8px;
    }
  }
  .sign-up-wrap {
    position: relative;
    display: flex;
    justify-content: center;
    flex: 1;
  }
  .sign-up-content{
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 480px;
    .to-sign-in {
      margin-top: 10px;
      color: var(--dark-3);
      line-height: 19px;
      text-align: left;
      > a {
        text-decoration: underline;
        font-weight: 500;
        color: var(--link-1);
      }
    }
    .input-second {
      margin-top: 32px;
    }
    .input {
      width: 480px;
    }
    .password-input {
      width: 230px;
    }
    .password {
      width: 480px;
      display: flex;
      justify-content: space-between;
    }
    .input {
      display: flex;
      margin-top: 24px;
      flex-direction: column;
      > span {
        color: var(--dark-2);
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 8px;
      }
    }
    .password-wrap {
      width: 230px;
    }
    .password-input {
      display: flex;
      margin-top: 24px;
      flex-direction: column;
      .password-title {
        height: 16px;
        color: var(--dark-2);
        font-weight: 500;
        line-height: 16px;
        margin-bottom: 8px;
      }
    }
    .terms-conditions {
      margin-top: 24px;
      color: var(--dark-3);
      font-size: var(--font-size-small);
      line-height: 18px;
      a {
        color: var(--dark-3);
        font-size: var(--font-size-small);
        line-height: 18px;
        text-align: left;
        text-decoration: underline;
        &:hover {
          color: var(--dark-2);
        }
      }
    }
    .sign-btn {
      width: 480px;
      margin-top: 30px;
      button {
        width: 100%;
      }
    }
    :deep(.ant-form) {
      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }
// @media screen and (max-width: 479px) {
//   .sign-up {
//     position: relative;
//     width: 100dvw;
//     height: auto;
//     min-height: 100dvh;
//     display: flex;
//     overflow: scroll;
//     padding-bottom: 130px;
//   }
//   .sign-up-wrap {
//     width: 100%;
//     display: flex;
//     justify-content: center;
//     .sign-up-content {
//       margin-top: 180px;
//       align-self: center;
//       position: relative;
//       z-index: 1;
//       width: calc(100dvw - 32px);
//       padding: 24px 32px;
//       width: 343px;
//       background: #FFFFFF;
//       box-shadow: 0 1px 0 0 #1a16250d;
//       border-radius: 16px;
//       > h3 {
//         font-family: Sora-Medium;
//         font-weight: 500;
//         font-size: 28px;
//         color: #0F0629;
//         letter-spacing: 0.5px;
//         line-height: 38px;
//       }
//       > p {
//         color: #6a647d;
//         font-size: 14px;
//         font-weight: 400;
//         line-height: 19px;
//         letter-spacing: 0.4px;
//         text-align: left;
//         margin-top: 8px;
//         > a {
//           display: block;
//           font-size: 14px;
//           font-weight: 500;
//           line-height: 20px;
//           letter-spacing: 0.4px;
//           text-align: left;
//         }
//       }
//     }
    
//     :deep(.ant-form) {
//       .ant-form-item {
//         margin-bottom: 0;
//       }
//     }
//     .password-input {
//       display: flex;
//       flex-direction: column;
//       margin-top: 32px;
//       > span {
//         opacity: 0.7;
//         font-family: Sora-Medium;
//         font-weight: 500;
//         font-size: 14px;
//         color: #0F0629;
//         letter-spacing: 0.4px;
//         line-height: 16px;
//         &:first-child {
//           margin-bottom: 8px;
//         }
//         input {
//           height: 40px;
//         }
//       }
//     }
//     .input {
//       margin-top: 32px;
//       display: flex;
//       flex-direction: column;
//       > span {
//         height: 16px;
//         opacity: 0.7;
//         font-family: Sora-Medium;
//         font-weight: 500;
//         font-size: 14px;
//         color: #0F0629;
//         letter-spacing: 0.4px;
//         line-height: 16px;
//         margin-bottom: 8px;
//       }
//     }
//     .sign-btn {
//       margin-top: 40px;
//       button {
//         width: 100%;
//         height: 40px;
//         background: #7D58E5;
//         box-shadow: 0 1px 0 0 #1a16250d;
//         border-radius: 20px;
//         font-family: Sora-Medium;
//         font-weight: 500;
//         font-size: 15px;
//         color: #FFFFFF;
//         letter-spacing: 0.4px;
//         text-align: center;
//       }
//     }
//     .terms-conditions {
//       margin-top: 40px;
//       color: #0f0629;
//       color: #0f0629;
//       font-size: 13px;
//       font-weight: 400;
//       line-height: 18px;
//       letter-spacing: 0.4px;
//       text-align: left;
//       > a {
//         text-decoration: underline;
//       }
//     }
//   }
// }
  
  
// </style>